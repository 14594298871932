<template>
  <div id="revenue-user">
    <div class="heading text-start">
      <div class="left-heading"><h2>List Project</h2></div>
      <div class="right-heading live-management-heading">
        <router-link :to="{ name: 'CreateProject' }">
          <button type="button" class="btn float-end me-2 w-184 btn-add-project">
          Add project
          </button>
        </router-link>
      </div>
    </div>
    <div class="content">
      <ValidationObserver>
        <div class="table-header row">
          <ValidationProvider
            name="Search project, division"
            v-slot="{ errors }"
            class="input-group search"
            rules="max:255"
          >
            <span class="input-group-text mb-2">
              <SearchIcon />
            </span>
            <input
              type="text"
              class="form-control mb-2"
              placeholder="Search project, division"
              v-model="filter.id"
              @input="debouncedQuery"
            />
            <span class="error text-left f-w3">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </ValidationObserver>
      <div class="table-content">
        <table class="table color-8B9DA5 text-start">
          <thead>
            <tr>
              <th scope="col" class="align-middle">Project name</th>
              <th scope="col" class="align-middle">Chatwork room</th>
              <th scope="col" class="align-middle">Division</th>
              <th scope="col" class="align-middle">Created date</th>
              <th scope="col" class="align-middle">Total messages to bot</th>
              <th scope="col" class="align-middle">Total messages not replied</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(project, index) in projectList" :key="index">
              <td class="pt-3 pb-3">{{ project.name }}</td>
              <td class="pt-3 pb-3"><a :href="project.chatworkLink" target="_blank" style="text-decoration: underline; color: #0d6efd">{{ project.chatworkRoomUrl }}</a></td>
              <td class="pt-3 pb-3">{{ project.divisionName }}</td>
              <td class="pt-3 pb-3 text-center">{{ project.time }}</td>
              <td class="pt-3 pb-3 text-center">{{ project.totalMessageBot }}</td>
              <td class="pt-3 pb-3 text-center">{{ project.totalMessageNotReply }}</td>
              <td class="pb-3">
                <router-link
                  :to="{ name: 'ProjectDetail', params: { id: project.id } }"
                >
                  <button class="btn">
                    <EyeIcon />
                  </button>
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{ name: 'EditProject', params: { id: project.id } }"
                >
                  <button class="btn">
                    <EditIcon />
                  </button>
                </router-link>
              </td>
              <td class="" v-show="user.roleInSystem == 'Owner' || user.roleInSystem == 'SystemAdmin' || user.roleInSystem == 'DLeadOrSubDlead'">
                <button class="btn" @click="handleDeleteProject(project)">
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <BasePaginate
      :current-page.sync="paginate.currentPage"
      :total-page.sync="paginate.total"
      :per-page.sync="paginate.perPage"
      :total-record.sync="paginate.totalRecord"
      @onPageChanged="changePage"
      v-if="paginate.total > 0"
    />
  </div>
</template>

<script>
import BasePaginate from "@/components/BasePaginate";
import SearchIcon from "@/components/Icon/SearchIcon";
import EyeIcon from "@/components/Icon/EyeIcon";
import EditIcon from "@/components/Icon/EditIcon";
import DeleteIcon from "@/components/Icon/DeleteIcon";
import moment from "moment";
import * as _ from "lodash";
import {mapGetters} from "vuex";
import { PER_PAGE_NUMBER } from "@/utils/const";
import {MESSAGES, MODAL} from "@/utils/const";

export default {
  name: "ListProject",
  components: {
    SearchIcon,
    EyeIcon,
    BasePaginate,
    DeleteIcon,
    EditIcon,
  },
  data() {
    return {
      projectList: [],
      paginate: {
        currentPage: 1,
        total: 0,
        totalRecord: 0,
      },
      filter: {
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  created() {
    this.getProjectList();
  },
  methods: {
    async getProjectList() {
      this.$root.$refs.loading.start();
      const params = { order: "DESC" };
      if (this.filter.id !== null && this.filter.id !== "") {
        params.q = this.filter.id;
      } else {
        params.q = undefined;
      }
      if (this.filter.date !== null && this.filter.date !== "") {
        params.date = this.filter.date;
      } else {
        params.date = undefined;
      }
      if (this.filter.user_type !== 1) {
        params.user_type = this.filter.user_type;
      } else {
        params.user_type = undefined;
      }
      params.limit = PER_PAGE_NUMBER;
      if (this.paginate.currentPage) {
        params.page = this.paginate.currentPage;
      }
      this.$store
        .dispatch("projects/getProjectList", params)
        .then((response) => {
          this.$root.$refs.loading.finish();
          this.paginate.currentPage = response.meta.page;
          this.paginate.totalRecord = response.meta.itemCount;
          this.paginate.total = response.meta.pageCount;
          this.projectList = response.data.map((user) => {
            user.time = moment(user.createdAt).format("YYYY-MM-DD");
            var match = /#!rid(.*)/.exec(user.chatworkRoomUrl);
            if (!_.isEmpty(match)) {
              user.chatworkLink = user.chatworkRoomUrl
              user.chatworkRoomUrl = match[1]
            }
            return user;
          });
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    changePage(page) {
      this.paginate.currentPage = page;
      this.getProjectList();
    },
    handleDeleteProject(project) {
      if(!confirm(`Are you sure to delete project: ${project.name}`)) {
        return;
      }
      this.$store
        .dispatch("projects/deleteProject", project.id)
        .then(() => {
          this.$root.$refs.loading.finish();
          this.$toast(
            MESSAGES.DELETE_PROJECT_SUCCESS,
            MODAL.MODAL_SUCCESS,
            MODAL.MODAL_TYPE_SUCCESS
          );
          this.getProjectList();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
      this.$root.$refs.loading.start();
    },
    debouncedQuery: _.debounce(function () {
      this.getProjectList();
    }, 500),
  },
};
</script>

<style scoped lang='scss'>
@import "@/assets/scss/listproject.scss";
</style>
